<template>
  <nuxt-img
    v-bind="$attrs"
    :sizes="sizes"
    :loading="lazy ? 'lazy' : 'eager'"
    provider="bigcommerce"
    format="webp"
    :fetchpriority="fetchpriority"
    @error="fallbackImageToOriginalSrc($event, $attrs.src as string)"
  />
</template>

<script setup lang="ts">
defineProps({
  // Can't use catch all v-bind $attrs for 'sizes', doesn't get formatted correctly
  sizes: {
    type: String,
    default: '',
  },
  lazy: {
    type: Boolean,
    default: true,
  },
  fetchpriority: {
    type: String,
    default: 'auto',
  },
});

const didFallback = ref(false);

const fallbackImageToOriginalSrc = (error: any, src: string) => {
  // Only try fallbacking once to avoid infinite loop
  if (!didFallback.value && src && error?.target) {
    /* eslint-disable no-param-reassign */
    error.onerror = null;
    error.target.srcset = '';
    error.target.removeAttribute('srcset');
    error.target.src = src;
    /* eslint-enable no-param-reassign */

    didFallback.value = true;
  }
};
</script>
